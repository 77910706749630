<template>
  <CModalBase :name="name" @hidden="onHidden">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 class="mb-10">
          <slot name="title">
            {{ $t('Confirm') }}
          </slot>
        </h3>
        <div class="text-sm mt-2">
          <slot name="question">
            {{ $t('Are you sure you want to delete?') }}
          </slot>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <slot name="buttons" v-bind="{ hide }">
        <div class="flex justify-end">
          <CButton type="outline" accent="secondary" class="text-tertiary" :disabled="busy" @click="hide">
            {{ $t('Cancel') }}
          </CButton>
          <CButton type="outline" accent="danger" class="ml-2 text-red-100" :disabled="busy" @click="onSubmit(hide)">
            {{ _confirmText }}
          </CButton>
        </div>
      </slot>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';

export default {
  name: 'ModalConfirmation',
  components: {
    CButton,
    CModalBase,
  },
  props: {
    name: {
      type: String,
      default: 'delete-confirmation-modal',
    },
    confirmText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    _confirmText() {
      if (!this.confirmText) {
        return this.$t('Delete');
      }
      return this.confirmText;
    },
  },
  methods: {
    setBusy(value) {
      this.busy = !!value;
    },
    onSubmit(hide) {
      this.$emit('submit', { hide, setBusy: this.setBusy });
    },
    onHidden() {
      this.setBusy(false);
      this.$emit('hidden');
    },
  },
};
</script>
